import { Box, Typography } from '@material-ui/core';
import React from 'react';

function AllAbout() {
  return (
    <React.Fragment>
      <Typography variant="h2" align="center" gutterBottom>
        All About
      </Typography>
      <Typography variant="body1" align="center">
        Nothing on this site should be taken seriously.
      </Typography>
    </React.Fragment>
  );
}

export default AllAbout