import React, { useEffect, useState } from 'react';
import './App.css';

import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Box, Button, Container, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from "@material-ui/core/CssBaseline";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link as RouterLink
} from 'react-router-dom';
import AllAbout from './AllAbout';
import BantsCentral from './BantsCentral';

const useStyles = makeStyles({
  menuButtons: {
    '& a': {
      margin: "0 0.3em"
    }
  },
});

function App() {
  const classes = useStyles();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [posts, setPosts] = useState();
  const [error, setError] = useState();
  const [isLoaded, setIsLoaded] = useState();

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        typography: {
          h1: {
            fontSize: "3rem"
          },
          h2: {
            fontSize: "2rem",
            fontWeight: 500
          },
          h3: {
            fontSize: "1.2rem",
            fontWeight: 600
          }
        },
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        }
      }),
    [prefersDarkMode],
  );

  useEffect(() => {
    fetch(process.env.REACT_APP_API_SERVER + "/posts/all")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setPosts(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          if (process.env.NODE_ENV == "development") { console.log(error); }
          setIsLoaded(true);
          setError(true);
        }
      )
  }, [])

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="md">
          <Box mt={2}>
            <Typography variant="h1" align="center">
              All About The Bants
            </Typography>
            <hr />
            <Box
              display="flex"
              justifyContent="center"
              className={classes.menuButtons}
            >
              <Button
                variant="outlined"
                component={RouterLink}
                to="/"
                size="small"
              >
                Bants Central
              </Button>
              <Button
                variant="outlined"
                component={RouterLink}
                to="/all-about"
                size="small"
              >
                All About
              </Button>
            </Box>
            <hr />
          </Box>

          <Routes>
            <Route path="all-about" element={<AllAbout />} />
            <Route element={
              <BantsCentral posts={posts} isLoaded={isLoaded} error={error} />
            } />
          </Routes>

          <hr />
          <Typography variant="body1" align="center">
            © All About The Bants | {new Date().getFullYear()}
          </Typography>
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;
