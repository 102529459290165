import { Box, Checkbox, FormControl, InputLabel, ListItemText, makeStyles, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import queryString from 'query-string'
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    marginBottom: "1em"
  },
  postPaper: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    whiteSpace: "pre-line"
  }
}));

function BantsCentral(props) {
  const classes = useStyles();
  const categories = [
    "Nicks Theories",
    "Savage Pete"
  ]
  const [selectedCategories, setSelectedCategories] = useState(categories);
  const location = useLocation();

  const queries = queryString.parse(location.search)

  useEffect(() => {
    let tempSelectedCategories = []
    if (queries.SavagePete === "true") {
      tempSelectedCategories.push("Savage Pete")
    }
    if (queries.NicksTheories === "true") {
      tempSelectedCategories.push("Nicks Theories")
    }
    if (tempSelectedCategories.length > 0) {
      setSelectedCategories(tempSelectedCategories)
    }
  }, [])

  let Posts = [];
  for (let index = 0; index < 20; index++) {
    Posts.push(
      <Skeleton
        className={classes.skeleton}
        key={index}
        variant="rect"
        height={118}
        animation="wave"
      />
    )
  }
  if (props.error) {
    Posts = (
      <Alert severity="error">
        Something went wrong.
      </Alert>
    )
  } else if (props.isLoaded && props.posts && !props.error) {
    props.posts.sort((a, b) => b.ID - a.ID)

    Posts = props.posts.map(post => {
      if (!selectedCategories.includes("Nicks Theories") && post.NicksTheories === "checked") {
        return ""
      }
      if (!selectedCategories.includes("Savage Pete") && post.SavagePete === "checked") {
        return ""
      }
      const date = new Date(post.Date)
      return (
        <Paper className={classes.postPaper} key={post.ID}>
          {
            post.Title === "" ? "" :
            <Typography variant="h3">
              <Box mb={4}>
                {post.Title}
              </Box>
            </Typography>
          }
          <Box component="p" fontSize="1.2rem" mb={4} mt={0}>
            {post.Content}
          </Box>
          <Box component="p" fontStyle="italic" fontSize="0.75rem" mb={0}>
            Category:
            {
              post.NicksTheories === "checked" ? " Nicks Theories" :
              post.SavagePete === "checked" ? " Savage Pete" : "None"
            }
            <br />
            Author(s): {post.Author}
            <br />
            Date: {date > 0 ? date.toLocaleDateString() : "N/A"}
            <br />
            ID: {post.ID}
          </Box>
        </Paper>
      )
    })
  }

  return (
    <React.Fragment>
      <Typography variant="h2" align="center">
        Bants Central
      </Typography>

      <Box
       mt={1}
        display="flex"
        justifyContent="center"
      >
        <FormControl style={{minWidth: 120}}>
          <InputLabel id="categories">Categories</InputLabel>
          <Select
            labelId="categories"
            multiple
            value={selectedCategories}
            id="categories"
            onChange={(e) => setSelectedCategories(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                <Checkbox checked={selectedCategories.indexOf(category) > -1} />
                <ListItemText primary={category} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box mt={1}>
        {Posts}
      </Box>

    </React.Fragment>
  );
}

export default BantsCentral